/// <reference path="../../../../global/DefinitelyTyped/tsd.d.ts" />

declare namespace EC{
	function addDoBasket():any;
};

module EC.item {
	export class Hash{
		private lastItem: string = null;
		private hashStart: string = "#item-";
		private modalId: string = "modalItem";
		constructor() {
//			$(window).on('hashchange', (e) => {
//				this.hashWasChange(e);
//			});
			this.createModalBasket();
			this.checkHashOnInit();
		}
		checkHashOnInit(): void {
			let hash: string = location.hash;
			if (hash.indexOf(this.hashStart) === 0) {
				let currentItem = hash.split(this.hashStart)[1];
				this.loadModal(currentItem);
			}
		}
		hashWasChange(e): void {
			this.checkHashOnInit();
		}
		createModalBasket () {
			if ($("#" + this.modalId).length === 0) {
				$("body").append($("<div class='modal fade' id='" + this.modalId + "' tabindex='-1' role='dialog'></div>"));
			}
		};
		loadModal(symbolItem: string): void {
			$.post("/do/ajax?fileName=cartModalOptionsItemHash.jsp&item=" + symbolItem).done((response) => {
				if (response.indexOf("onlyAlert") >= 0) {
					this.showNotificationBar(response);
				} else {
					EC.addDoBasket['loadedContentModalOptions'](this.modalId, response);
				}
			});
		}
		showNotificationBar(message, duration = 4000, bgColor = "#F4E0E1", txtColor = "#A42732", height = 40) {
			if ($('#notification-bar').length === 0) {
				var HTMLmessage = "<div class='notification-message' style='text-align:center; line-height: " + height + "px;'> " + message + " </div>";
				$('body').prepend("<div id='notification-bar' style='display:none; width:100%; height:" + height + "px; background-color: " + bgColor + "; position: fixed; z-index: 999999; color: " + txtColor + ";border-bottom: 1px solid " + txtColor + ";'>" + HTMLmessage + "</div>");
			}
			$('#notification-bar').slideDown(duration, () => {
					$('#notification-bar').slideUp();
			});
		}
	}
}